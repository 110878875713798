const tfLabel = [
  { label: "Ja", value: true },
  { label: "Nein", value: false },
];

export const ContactConstant = {
  firstName: {
    label: "Vorname",
  },
  lastName: {
    label: "Nachname",
  },
  email: {
    label: "Email",
  },
  strasse: {
    label: "Strasse",
  },
  plz: {
    label: "Postleitzahl",
  },
  ort: {
    label: "Ort",
  },
  phone: {
    label: "Telefon",
  },

  //   morgens: {
  //     label: "Morgens",
  //   },
  //   mittags: {
  //     label: "Mittags",
  //   },
  //   abends: {
  //     label: "Abends",
  //   },
  //   Montag: {
  //     label: "Montag",
  //   },
  //   Dienstag: {
  //     label: "Dienstag",
  //   },
  //   Mittwoch: {
  //     label: "Mittwoch",
  //   },
  //   Donnerstag: {
  //     label: "Donnerstag",
  //   },
  //   Freitag: {
  //     label: "Freitag",
  //   },

  // day: {
  //     label: 'day',
  //     data: [
  //         { label: 'Mo', value: 1 },
  //         { label: 'Di', value: 2 },
  //         { label: 'Mi', value: 3 },
  //         { label: 'Do', value: 4 },
  //         { label: 'Fr', value: 5 },
  //         { label: 'Sa', value: 6 },
  //         // { label: 'Sn', value: 0 },
  //     ]
  // },
  time: {
    label: "Erreichbarkeit",
    data: [
      { label: "Mo", value: "Mo" },
      { label: "Di", value: "Di" },
      { label: "Mi", value: "Mi" },
      { label: "Do", value: "Do" },
      { label: "Fr", value: "Fr" },
      { label: "morgens", value: "morgens" },
      { label: "mittags", value: "mittags" },
      { label: "abends", value: "abends" },
    ],
  },
  concern: {
    label: "Anliegen Typ",
    data: [
      { label: "Allgemeine Elektro Installationen", value: 0 },
      { label: "Photovoltaik", value: 1 },
      { label: "Internet/Netzwerk/Telefon", value: 2 },
      { label: "E-Mobilität", value: 3 },
      { label: "Allgemeine Anfrage", value: 4 },
    ],
  },
  message: {
    label: "Message",
  },

  // Photovoltaik
  addressIdentical: {
    label: "AddresseIdentisch",
    data: [
      { label: "Ja", value: true },
      { label: "Nein", value: false },
    ],
  },
  objectAddress: {
    label: "ObjektAdresse",
  },
  houseKind: {
    label: "HouseArt",
    data: [
      { label: "Neubau", value: 0 },
      { label: "Bestehend", value: 1 },
    ],
  },

  // E-mobility
  renter: {
    label: "KundeIst",
    data: [
      { label: "Mieter", value: 1 },
      { label: "Eigentümer", value: 2 },
      { label: "Verwaltung", value: 3 },
    ],
  },
  garageKind: {
    label: "GarageArt",
    data: [
      { label: "Sammelgarage", value: 0 },
      { label: "Einzelgarage", value: 1 },
      { label: "AusseParkplatz", value: 2 },

    ],
  },
  installMethod: {
    label: "installationsMethode",
    data: [
      { label: "Installation Ladestation", value: 0 },
      { label: "Installation Lademanagement", value: 1 },
    ],
  },
  charginStation: {
    label: "AufladeStation",
    data: [
      {
        label: "Ladestation vorhanden - wir wünschen eine installation",
        value: 0,
      },
      {
        label:
          "Ladestation nicht vorhanden - wir wünschen Lieferung, Installation und inbetriebnahme",
        value: 1,
      },
      {
        label:
          "Wir wünschen eine Beratung",
        value: 2,
      },
    ],
  },

  // Internet/Telefon/Netzwerk
  itnMode: {
    label: "InternetNetzwerk",
    data: [
      { label: "Internet / Telefon", value: 0 },
      { label: "Netzwerk", value: 1 },
    ],
  },
  itnRef: {
    label: "Referenz",
    data: [
      { label: "Umzug", value: 0 },
      { label: "Neuanmeldung", value: 1 },
      { label: "Unterhalt", value: 2 },
      { label: "Anderes", value: 3 },


    ],
  },
  activationDate: {
    label: "aktiviertungsDatum",
  },
  offerer: {
    label: "anbieter",
    data: [
      { label: "Swisscom", value: 0 },
      { label: "Salt", value: 1 },
      { label: "Sunrise", value: 2 },
      { label: "UPC", value: 3 },
      { label: "Iway", value: 4 },
      { label: "Andere", value: 5 },
    ],
  },

  // Electro Installation
  eleInstallType: {
    label: "eleInstallType",
    data: [
      { label: "Allgemeine Installationen", value: 0 },
      { label: "Küche", value: 1 },
      { label: "Bad", value: 2 },
      { label: "Haustechnik", value: 3 },
      { label: "Anderes", value: 4 },

    ],
  },
  eleLampReplacement: {
    label: "Leuchtmittel Austausch",
    data: tfLabel,
  },
  eleRepair: {
    label: "Reparatur",
    data: tfLabel,
  },
  eleSocketNotWoking: {
    label: "Anderes",
    data: tfLabel,
  },
  eleDefectReport: {
    label: "Defekt melden",
    data: tfLabel,
  },
  eleInstallNewKitchen: {
    label: "Installation / Neubau Küche",
    data: tfLabel,
  },
  eleReplaceIndividualComponent: {
    label: "Austausch einzelner Komponenten",
    data: tfLabel,
  },
  eleRepairOther: {
    label: "Beschreibung",
    data: tfLabel,
  },
  eleRepairIndividualComponent: {
    label: "Reparatur / Defekt einzelner Komponenten",
    data: tfLabel,
  },
  eleInstallNewBath: {
    label: "Installation / Neubau Bad",
    data: tfLabel,
  },
  eleReinstallation: {
    label: "NeuInstallation",
    data: tfLabel,
  },
  report: {
    label: "Meldung",
  },
};
