import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Formik, Form, FastField, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import {
  Error,
  Center,
  InputField,
  AttachButton,
  ContactLabel,
  FileSpan,
  CheckboxLabel,
} from "./styles";
import { Button, Input, CustomSelect } from "components/common";
import { Col, FormFeedback, Row } from "reactstrap";
import NetzwerkTelefon from "./itn";
import EMobility from "./emobility";
import Photovoltaic from "./photovoltaic";
import ElectroInstallation from "./elektro";
import CommonContactForm from "./common";
import { url } from "data/config";
import { formspree_url } from "data/config";
import { ContactConstant } from "./constant";
import { formatFormspreeData } from "./utils";

const ContactForm = ({prop}) => {
  const [files, setFiles] = useState([]);
  const fileEl = useRef(null);
  // const [fileNames, setFileNames] = useState('No file attached');
  const componentRef = useRef(null);

  const scrollToComponent = () => {
    const yOffset = -80; // Adjust the offset as needed
    const element = componentRef.current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
  };

  useEffect(() => {
    if (prop.concernProp) {
      scrollToComponent();
    }
  }, []);
  useEffect(() => {
    // if (files.length == 0)
    //     setFileNames('No file attached');
    // else {
    //     let list = [];
    //     for (let i = 0;i < files.length;i++)
    //         list.push(files[i].name);
    //     setFileNames(list.join(', '));
    // }
    // console.log(files)
  }, [files]);

  const appendFile = (data) => {
    setFiles([...files, data]);
  };
  const detachFile = (i) => {
    setFiles([...files.slice(0, i), ...files.slice(i + 1, files.length)]);
  };

  

  const initialValues = {
    // Common values
    firstName: "",
    lastName: "",
    email: "",
    strasse: "",
    plz: "",
    ort: "",
    phone: "",
    concern: prop.concernProp === "photovoltaik" ? 1 :
    prop.concernProp === "internet" ? 2   :
    prop.concernProp === "emobility" ? 3   :
    prop.concernProp === "elektroinstallationen" ? 0 : 0, // 0~4
    success: false,

    time: -1,
    // General request
    message: "",

    // photovoltaik
    addressIdentical: true,
    objectAddress: "",
    houseKind: 0,

    // emobility
    renter: 0,
    garageKind: 0,
    installMethod: 0,
    charginStation: 0,

    // Internet/Telefon/Netzwerk
    itnMode: 0,
    itnRef: 0,
    activationDate: "",
    offerer: 0,

    // Electro Installation
    eleInstallType: 0,
    eleLampReplacement: false,
    eleRepair: false,
    eleSocketNotWoking: false,
    eleDefectReport: false,
    eleInstallNewKitchen: false,
    eleReplaceIndividualComponent: false,
    eleRepairIndividualComponent: false,
    eleInstallNewBath: false,
    eleReinstallation: false,
    report: "",

    file: null,
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Pflichtfeld"),
    lastName: Yup.string().required("Pflichtfeld"),
    email: Yup.string().email().required("Pflichtfeld"),
    strasse: Yup.string().required("Pflichtfeld"),
    plz: Yup.string().required("Pflichtfeld"),
    ort: Yup.string().required("Pflichtfeld"),

    phone: Yup.string().required("Pflichtfeld"),
    // day: Yup.number().positive("Pflichtfeld"),
    // time: Yup.number().positive("Pflichtfeld"),

    // Photovoltaic
    objectAddress: Yup.string().when(["concern", "addressIdentical"], {
      is: (concern, addressIdentical) =>
        concern === 1 && addressIdentical === false,
      then: Yup.string().required("Pflichtfeld"),
    }),
    activationDate: Yup.string().when(["concern", "itnMode"], {
      is: (concern, itnMode) => concern === 2 && itnMode === 0,
      then: Yup.string().required("Pflichtfeld"),
    }),
    eleInstallType: Yup.number(),
    eleDefectReport: Yup.boolean(),
    report: Yup.string().when(
      ["concern", "eleInstallType", "eleDefectReport"],
      {
        is: (concern, eleInstallType, eleDefectReport) =>
          concern === 0 && eleInstallType === 0 && eleDefectReport === true,
        then: Yup.string().required("Pflichtfeld"),
      }
    ),
    // message: Yup.string().when(["concern", "itnMode"], {
    //   is: (concern, itnMode) =>
    //     concern === 0 || concern === 4 || (concern === 3 && itnMode === 1),
    //   then: Yup.string().required("Pflichtfeld"),
    // }),
  });

  const onFormikChange = (e) => {};

  const onSubmit = async (
    values,
    { setSubmitting, setFieldValue, resetForm }
  ) => {
    const formspreeData = formatFormspreeData(values);
    let formData = new FormData();
    for (let key in formspreeData) formData.append(key, formspreeData[key]);
    for (let i = 0; i < files.length; i++) formData.append("attach", files[i]);
    formData.append("erreichbarkeit", values.time);
    try {
      const { data } = await axios.post(formspree_url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setSubmitting(false);
      setFieldValue("success", true);
      setTimeout(() => resetForm(), 6000);

      alert("Success");
    } catch (err) {
      setSubmitting(false);
      setFieldValue("success", false);
      alert(
        "Etwas hat nmicht richtig funktioniert. Bitte versuchen Sie es noch einmal"
      );
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onChange={onFormikChange}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({
        errors,
        touched,
        values,
        isSubmitting,
        handleSubmit,
        setFieldValue,
      }) => (
        <Form onSubmit={handleSubmit}>
          <label ref={componentRef} 
          style={{fontSize:'14px'}}>
            Mein Anliegen betrifft das folgende Thema:
          </label>
          <Row>
            <Col md="10" sm="12">
              <Field
                className="custom-select"
                name="concern"
                options={ContactConstant.concern.data}
                component={CustomSelect}
                isMulti={false}
                // onChange={() => console.log(values)}
              />
            </Col>
          </Row>

          {/* General request */}

          <InputField>
            <Input
              as={FastField}
              component="textarea"
              aria-label="message"
              id="message"
              rows="8"
              type="text"
              name="message"
              placeholder="Freies Textfeld"
              error={touched.message && errors.message}
            />
            <ErrorMessage component={Error} name="message" />
          </InputField>

          <div style={{ marginBottom: "10px" }}>
            <ContactLabel>Datei Anhängen</ContactLabel>
            <AttachButton type="button" onClick={() => fileEl?.current.click()}>
              Datei Anhängen
            </AttachButton>
            {files.length == 0 && <label>No file attached</label>}
            {files.map((f, i) => (
              <FileSpan key={i} onClick={() => detachFile(i)}>
                {f.name}
              </FileSpan>
              // <FileSpan></FileSpan>
            ))}
            {/* <Field ref={fileEl} type="file" name="file" id="file" multiple onChange={(e) => {
                                setFiles([...e.target.files]);
                            }} /> */}
            <input
              type="file"
              ref={fileEl}
              style={{ display: "none" }}
              onChange={(e) => {
                // setFiles([...e.target.files]);
                appendFile(e.target.files[0]);
              }}
            />
          </div>

          <CommonContactForm
            values={values}
            touched={touched}
            errors={errors}
          />


          {/* Photovoltaik */}
          {values.concern === 1 && (
            <Photovoltaic values={values} touched={touched} errors={errors} />
          )}

          {/* E-Mobilität  */}
          {values.concern === 3 && (
            <EMobility values={values} touched={touched} errors={errors} />
          )}

          {/* Internet/Telefon/Netzwerk */}
          {values.concern === 2 && (
            <NetzwerkTelefon
              values={values}
              touched={touched}
              errors={errors}
            />
          )}

          {/* Allgemeine Elektro Installationen */}
          {values.concern === 0 && (
            <ElectroInstallation
              values={values}
              touched={touched}
              errors={errors}
            />
          )}

          {/* File upload */}

          <Center>
            <Button primary type="submit" disabled={isSubmitting}>
              Senden
            </Button>
          </Center>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
