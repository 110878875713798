import React from 'react';
import { Container } from 'components/common';
import { Wrapper, Details } from './styles';
import ContactForm from './ContactForm';

export const Contact = (concernprop) =>(
  <Wrapper as={Container} id="contact">
    <Details>
      <ContactForm prop={concernprop} />
    </Details>
  </Wrapper>
);
