import React from 'react';
import { Row, Col } from 'reactstrap';
import { Error, Center, InputField } from "./styles";
import { Formik, Form, FastField, ErrorMessage, Field } from "formik";
import { Button, Input, CustomSelect } from "components/common";
import { ContactConstant } from './constant';

const NetzwerkTelefon = ({ values, touched, errors }) => {
    return (
        <>
            <label>Es geht um</label>
            <Row>
                <Col md='4'>
                    <Field
                        className="custom-select"
                        name="itnMode"
                        options={ContactConstant.itnMode.data}
                        component={CustomSelect}
                    // isMulti={false} 
                    />
                </Col>
            </Row>

            {values.itnMode === 0 &&
                <>
                    <label>Bezüglich</label>
                    <Row>
                        <Col md='6'>
                            <Field
                                className="custom-select"
                                name="itnRef"
                                options={ContactConstant.itnRef.data}
                                component={CustomSelect}
                                isMulti={false}
                            />
                        </Col>
                    </Row>

                    <label>Gewünschter Aufschalttermin</label>
                    <Row>
                        <Col md='6'>
                            <InputField>
                                <Input
                                    as={FastField}
                                    type="text"
                                    name="activationDate"
                                    component="input"
                                    aria-label="name"
                                    placeholder="Freies Textfeld ..."
                                />
                                <ErrorMessage component={Error} name="name" />
                            </InputField>
                        </Col>
                    </Row>

                    <label>Anbieter</label>
                    <Row>
                        <Col md='6'>
                            <Field
                                className="custom-select"
                                name="offerer"
                                options={ContactConstant.offerer.data}
                                component={CustomSelect}
                                isMulti={false}
                            />
                        </Col>
                    </Row>
                </>
            }

            {values.itnMode === 1 &&
                <InputField>
                    <Input
                        as={FastField}
                        component="textarea"
                        aria-label="message"
                        id="message"
                        rows="8"
                        type="text"
                        name="Nachricht"
                        placeholder="Freies Textfeld"
                    />
                    <ErrorMessage component={Error} name="message" />
                </InputField>
            }
        </>
    )
};

export default NetzwerkTelefon;