import React from "react";
import { Layout, Seo } from "components/common";
import { MainContent } from "components/MainContent"

import { kontaktContent } from "pagesData/kontakt";
import { IntroRandoAnim } from "components/IntroRandomAnim";

import { Contact } from "components/Contact";


const Kontakt = ({ location }) =>

{ 
  
  const params = new URLSearchParams(location.search);
  const parameter = params.get("anliegen");

  return (
  <Layout>
    <Seo />
    <IntroRandoAnim {...kontaktContent} />
    <MainContent content={kontaktContent.mainContent} />
    {/* <Tabs content={[<Contact />, <Contact2 />, <Contact3 />, <Contact4 />]} /> */}
    <Contact concernProp={parameter} />
  </Layout>
);
}
export default Kontakt;
