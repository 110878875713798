import React from "react";
import { Row, Col, Label } from "reactstrap";
import {
  Error,
  Center,
  InputField,
  CheckboxField,
  CheckboxLabel,
} from "./styles";
import { Formik, Form, FastField, ErrorMessage, Field } from "formik";
import { Button, CustomSelect, Input } from "components/common";
import { ContactConstant } from "./constant";

const ElectroInstallation = ({ values, touched, errors }) => {
  return (
    <>
      <label>Es geht um</label>
      <Row>
        <Col md="8">
          <Field
            className="custom-select"
            name="eleInstallType"
            options={ContactConstant.eleInstallType.data}
            component={CustomSelect}
            placeholder="Uhrzeit"
            isMulti={false}
          />
        </Col>
      </Row>

      {/* Allgemeine Installationen */}
      {values.eleInstallType == 0 && (
        <>
          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleLampReplacement"
              component={Input}
            />
            <span>Leuchtmittel Austausch</span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field type="checkbox" name="eleRepair" component={CheckboxField} />
            <span>Reparatur</span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field type="checkbox" name="eleDefectReport" component="input" />
            <span>Defekt melden</span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleSocketNotWoking"
              component={CheckboxField}
            />
            <span>Anderes</span>
          </CheckboxLabel>

          <InputField>
            <Input
              as={FastField}
              component="textarea"
              aria-label="report"
              rows="3"
              type="text"
              name="report"
              placeholder="Freies Textfeld"
            />
            <ErrorMessage component={Error} name="report" />
          </InputField>
        </>
      )}

      {/* Küche */}
      {values.eleInstallType == 1 && (
        <>
          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleInstallNewKitchen"
              component="input"
            />
            <span>Installation / Neubau Küche</span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleReplaceIndividualComponent"
              component="input"
            />
            <span>Austausch einzelner Komponenten</span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleRepairIndividualComponent"
              component="input"
            />
            <span>Reparatur / Defekt </span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleRepairOther"
              component="input"
            />
            <span>Anderes</span>
          </CheckboxLabel>

            <InputField>
              <Input
                as={FastField}
                component="textarea"
                aria-label="report"
                rows="3"
                type="text"
                name="report"
                placeholder="Freies Textfeld"
              />
              <ErrorMessage component={Error} name="report" />
            </InputField>

        </>
      )}

      {/* Bad */}
      {values.eleInstallType == 2 && (
        <>
          <CheckboxLabel>
            <Field type="checkbox" name="eleInstallNewBath" component="input" />
            <span>Installation / Neubau Bad</span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleReplaceIndividualComponent"
              component="input"
            />
            <span>Austausch einzelner Komponenten</span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleRepairIndividualComponent"
              component="input"
            />
            <span>Reparatur / Defekt </span>
          </CheckboxLabel>
          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleRepairOther"
              component="input"
            />
            <span>Anderes</span>
          </CheckboxLabel>

            <InputField>
              <Input
                as={FastField}
                component="textarea"
                aria-label="report"
                rows="3"
                type="text"
                name="report"
                placeholder="Freies Textfeld"
              />
              <ErrorMessage component={Error} name="report" />
            </InputField>

        </>
      )}

      {/* Haustechnik */}
      {values.eleInstallType == 3 && (
        <>
          <CheckboxLabel>
            <Field type="checkbox" name="eleReinstallation" component="input" />
            <span>NeuInstallation</span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleReplaceIndividualComponent"
              component="input"
            />
            <span>Austausch einzelner Komponenten</span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleRepairIndividualComponent"
              component="input"
            />
            <span>Reparatur / Defekt </span>
          </CheckboxLabel>

          <CheckboxLabel>
            <Field
              type="checkbox"
              name="eleRepairOther"
              component="input"
            />
            <span>Anderes</span>
          </CheckboxLabel>

            <InputField>
              <Input
                as={FastField}
                component="textarea"
                aria-label="report"
                rows="3"
                type="text"
                name="report"
                placeholder="Freies Textfeld"
              />
              <ErrorMessage component={Error} name="report" />
            </InputField>

          
        </>
      )}

      {values.eleInstallType == 4 && (
        <InputField>
          <Input
            as={FastField}
            component="textarea"
            aria-label="report"
            rows="3"
            type="text"
            name="report"
            placeholder="Beschreibeiben Sie Ihr Anliegen"
          />
          <ErrorMessage component={Error} name="report" />
        </InputField>
      )}

      {/* <InputField>
                <Input
                    as={FastField}
                    component="textarea"
                    aria-label="message"
                    id="message"
                    rows="5"
                    type="text"
                    name="message"
                    placeholder="Allgemeine Beschreibung"
                />
                <ErrorMessage component={Error} name="message" />
            </InputField> */}
    </>
  );
};
export default ElectroInstallation;
