// kontakt page - pages/kontakt.js

// import kontakt from "assets/bilder/kontakt/_AD_8795_rgb_final.jpg";
import bg1 from "assets/bilder/kontakt/AD_8795_rgb.jpg"
import bg2 from "assets/bilder/kontakt/AD_9538_rgb.jpg"
import bg3 from "assets/bilder/kontakt/BAD_0145_rgb.jpg"

export const kontaktContent = {
    h2: "Kontakt",
    subtitle: "Wir freuen uns auf Sie",
    // background: kontakt,
    bgs: [
      bg1,bg2,bg3
    ],
    mainContent: `<p>
      <b>Elektro Huwiler GmbH</b> <br/>
        Stallikonerstrasse 73<br/>
        8903 Birmensdorf - Zürich<br/>
           Telefon:<a href="tel:0447776544"> 044 777 65 44   </a>  <br/>
            <br/>
            Allgemeine Geschäftszeiten <br/>
            Montag – Freitag: 07.30 – 12.00 / 13.00 – 17.00
            <br/>
            Pikettnummer für Notfälle:
            <a href="tel:0798006544"> 079 800 65 44 </a>  
                    <br/>                    <br/>

                    Gerne setzen wir uns mit Ihnen in Verbindung und kümmern uns um Ihr Anliegen.
                    Wir bitten Sie daher für einen vereinfachten Ablauf, das Kontaktformular entsprechend auszufüllen. Dies ist unverbindlich und vereinfacht den Lösungsprozess.
                    <br/>

        <br/>  Herzlichen Dank!
       
        </p>
    `,
}