import styled from 'styled-components';

export const Error = styled.span`
  color: #ff4136;
`;

export const Center = styled.div`
  text-align: left;

  h4 {
    font-weight: normal;
  }
`;

export const InputField = styled.div`
  position: relative;
  margin-bottom: 0.7rem;
`;

export const CheckboxField = styled.input`
  width: 20px;
  height: 20px;
  outline: 1px solid #707070;
`;

export const CheckboxLabel = styled.label`
  display: block;
  margin-bottom: 1rem;
//   background:grey;
  input {
    width: 20px;
    height: 20px;
    outline: 1px solid #707070;
    margin-right: 10px;
  }
`;

export const ContactLabel = styled.label`
  display: block;
  margin-bottom: .5rem;
  font-size: 14px;
`;
export const TimeSpan = styled.span`
  font-size: 14px;
  color: black;
  margin-right: 16px;
  margin-left: 5px;
`;

export const AttachButton = styled.button`
  border-radius: 10px;
  background-color: #d9d9d9;
  padding: 10px 20px;
  border: none;
  margin-right: 20px;

  &:hover {
    background-color: #cdcdcd;
  }
`;

export const FileSpan = styled.button`
  position:relative;
  color: black;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
  border-color: transparent;
  background: #f0f0f0;

  &:hover {
    background: rgba(240, 50, 100, 0.5);
  }

  
`;