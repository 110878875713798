import React from "react";
import { Row, Col } from "reactstrap";
import {
  Error,
  InputField,
  TimeSpan,
} from "./styles";
import { FastField, ErrorMessage, Field } from "formik";
import {  Input, } from "components/common";
import { ContactConstant } from "./constant";

const CommonContactForm = ({ values, touched, errors }) => {
  return (
    <>
      <Row>
        {/* Name */}
        <label style={{ fontSize: "14px" }}>
          Name<span style={{ color: "red", marginLeft: "3px" }}>*</span>
        </label>

        <Col md="6" sm="12">
          <InputField>
            <Input
              as={FastField}
              type="text"
              name="lastName"
              component="input"
              aria-label="Name"
              placeholder="Name"
              error={touched.name && errors.name}
            />
            <ErrorMessage component={Error} name="lastName" />
          </InputField>
        </Col>

        {/* Vorname */}

        <label style={{ fontSize: "14px" }}>
          Vorname<span style={{ color: "red", marginLeft: "3px" }}>*</span>
        </label>

        <Col md="6" sm="12">
          <InputField>
            <Input
              as={FastField}
              type="text"
              name="firstName"
              component="input"
              aria-label="Vorname"
              placeholder="Vorname"
              error={touched.name && errors.name}
            />
            <ErrorMessage component={Error} name="firstName" />
          </InputField>
        </Col>

        {/* Adresse */}

        <label style={{ fontSize: "14px" }}>
          Strasse<span style={{ color: "red", marginLeft: "3px" }}>*</span>
        </label>
        <Col md="6" sm="12">
          <InputField>
            <Input
              as={FastField}
              type="text"
              name="strasse"
              component="input"
              aria-label="Strasse"
              placeholder="Strasse"
              error={touched.name && errors.name}
            />
            <ErrorMessage component={Error} name="strasse" />
          </InputField>
        </Col>

        <label style={{ fontSize: "14px" }}>
          Postleitzahl<span style={{ color: "red", marginLeft: "3px" }}>*</span>
        </label>

        <Col md="6" sm="12">
          <InputField>
            <Input
              as={FastField}
              type="text"
              name="plz"
              component="input"
              aria-label="Postleitzahl"
              placeholder="Postleitzahl"
              error={touched.name && errors.name}
            />
            <ErrorMessage component={Error} name="plz" />
          </InputField>
        </Col>

        <label style={{ fontSize: "14px" }}>
          Ort<span style={{ color: "red", marginLeft: "3px" }}>*</span>
        </label>

        <Col md="6" sm="12">
          <InputField>
            <Input
              as={FastField}
              type="text"
              name="ort"
              component="input"
              aria-label="Ort"
              placeholder="Ort"
              error={touched.name && errors.name}
            />
            <ErrorMessage component={Error} name="ort" />
          </InputField>
        </Col>

        {/* Email */}
        <label style={{ fontSize: "14px" }}>
          Email<span style={{ color: "red", marginLeft: "3px" }}>*</span>
        </label>

        <Col md="6" sm="12">
          <InputField>
            <Input
              as={FastField}
              type="email"
              name="email"
              component="input"
              aria-label="Email"
              placeholder="Email"
              error={touched.name && errors.name}
            />
            <ErrorMessage component={Error} name="email" />
          </InputField>
        </Col>

        {/* Telefon */}
        <label style={{ fontSize: "14px" }}>
          Telefon<span style={{ color: "red", marginLeft: "3px" }}>*</span>
        </label>

        <Col md="6" sm="12">
          <InputField>
            <Input
              as={FastField}
              type="text"
              name="phone"
              component="input"
              aria-label="Telefon"
              placeholder="Telefon"
              error={touched.name && errors.name}
            />
            <ErrorMessage component={Error} name="phone" />
          </InputField>
        </Col>
      </Row>

      <label>Erreichbarkeit</label>
      <div style={{ height: "6px" }}></div>
      <Row>
        <Col>
          <label>
            <Field
              style={{ width: "22px", height: "22px" }}
              type="checkbox"
              name="time"
              value={ContactConstant.time.data[0].value}
            />
            <TimeSpan>Mo</TimeSpan>
          </label>
          <label>
            <Field
              style={{ width: "22px", height: "22px" }}
              type="checkbox"
              name="time"
              value={ContactConstant.time.data[1].value}
            />
            <TimeSpan>Di</TimeSpan>
          </label>

          <label>
            <Field
              style={{ width: "22px", height: "22px" }}
              type="checkbox"
              name="time"
              value={ContactConstant.time.data[2].value}
            />

            <TimeSpan>Mi</TimeSpan>
          </label>
          <label>
            <Field
              style={{ width: "22px", height: "22px" }}
              type="checkbox"
              name="time"
              value={ContactConstant.time.data[3].value}
            />

            <TimeSpan>Do</TimeSpan>
          </label>
          <label>
            <Field
              style={{ width: "22px", height: "22px" }}
              type="checkbox"
              name="time"
              value={ContactConstant.time.data[4].value}
            />
            <TimeSpan>Fr</TimeSpan>
          </label>
        </Col>
      </Row>
      <div style={{ height: "12px" }}></div>
      <Row>
        <Col>
          <label>
            <Field
              style={{ width: "22px", height: "22px" }}
              type="checkbox"
              name="time"
              value={ContactConstant.time.data[5].value}
            />
            <TimeSpan>morgens</TimeSpan>
          </label>

          <label>
            <Field
              style={{ width: "22px", height: "22px" }}
              type="checkbox"
              name="time"
              value={ContactConstant.time.data[6].value}
            />
            <TimeSpan>mittags</TimeSpan>
          </label>
          <label>
            <Field
              style={{ width: "22px", height: "22px" }}
              type="checkbox"
              name="time"
              value={ContactConstant.time.data[7].value}
            />
            <TimeSpan>abends</TimeSpan>
          </label>
        </Col>
      </Row>

      <div style={{ height: "15px" }}></div>
    </>
  );
};
export default CommonContactForm;
