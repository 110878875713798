import React from 'react';
import { Row, Col } from 'reactstrap';
import {  Field } from "formik";
import {  CustomSelect } from "components/common";
import { ContactConstant } from './constant';

const EMobility = ({ values, touched, errors }) => {
    return (
        <>
            <label>Ich bin</label>
            <Row>
                <Col md='4'>
                    <Field
                        className="custom-select"
                        name="renter"
                        options={ContactConstant.renter.data}
                        component={CustomSelect}
                        isMulti={false}
                    />
                </Col>
            </Row>

            <label>Es geht um eine</label>
            <Row>
                <Col md='4'>
                    <Field
                        className="custom-select"
                        name="garageKind"
                        options={ContactConstant.garageKind.data}
                        component={CustomSelect}
                        isMulti={false}
                    />
                </Col>
            </Row>
            <Row>
                <Col md='4'>
                    <Field
                        className="custom-select"
                        name="installMethod"
                        options={ContactConstant.installMethod.data}
                        component={CustomSelect}
                        isMulti={false}
                    />
                </Col>
            </Row>

            <label>Ladestation Bauseitig</label>
            {/* Charging station available - we would like an installation */}
            {/* Charging station NOT available - we want delivery, installation and commissioning */}
            <Field
                className="custom-select"
                name="charginStation"
                options={ContactConstant.charginStation.data}
                component={CustomSelect}
                isMulti={false}
            />
        </>
    )
}

export default EMobility;