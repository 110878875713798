import React from 'react';
import { Row, Col } from 'reactstrap';
import { Error, Center, InputField } from "./styles";
import { Formik, Form, FastField, ErrorMessage, Field } from "formik";
import { Button, Input, CustomSelect } from "components/common";
import { ContactConstant } from './constant';

const Photovoltaic = ({ values, touched, errors }) => {
    return (
        <>
            <label>Adresse identisch mit Oben ausgefüllter adresse</label>
            <Row>
                <Col md='4'>
                    <Field
                        className="custom-select"
                        name="addressIdentical"
                        options={ContactConstant.addressIdentical.data}
                        component={CustomSelect}
                        isMulti={false}
                    />
                </Col>
            </Row>

            {!values.addressIdentical &&
                <>
                    <Row><Col md='8'>
                        <InputField>
                            <Input
                                as={FastField}
                                type="text"
                                name="objectAddress"
                                component="input"
                                aria-label="Adresse des Objekts"
                                placeholder="Adresse des Objekts"
                                error={touched.name && errors.name}
                            />
                            <ErrorMessage component={Error} name="objectAddress" />
                        </InputField>
                    </Col></Row>
                </>
            }

            <label>Um was für ein Haus handelt es sich?</label>
            <Row>
                <Col md='4'>
                    <Field
                        className="custom-select"
                        name="houseKind"
                        options={ContactConstant.houseKind.data}
                        component={CustomSelect}
                        isMulti={false}
                    />
                </Col>
            </Row>
        </>
    )
}

export default Photovoltaic;