import { ContactConstant } from "./constant";

export const formatFormspreeData = (values) => {

    let fields = ['firstName', 'lastName','email', 'time', 'strasse', 'plz', 'ort', 'phone','concern'];

    // General request
    // if (values.concern === 4) fields.push('message');

    // Photovoltaik
    if (values.concern === 1) {
        fields.push('addressIdentical');
        if (values.addressIdentical === false) fields.push('objectAddress');
        fields.push('houseKind');
    }

    // E-Mobilität
    if (values.concern === 3) {
        fields.push('renter');
        fields.push('garageKind');
        fields.push('installMethod');
        fields.push('charginStation');
    }

    // Internet/Telefon/Netzwerk
    if (values.concern === 2) {
        fields.push('itnMode');
        if (values.itnMode === 0) {
            fields.push('itnRef');
            fields.push('activationDate');
            fields.push('offerer');
        }
        if (values.itnMode === 1)
            fields.push('message');
    }

    // Allgemeine Elektro Installationen
    if (values.concern === 0) {
        fields.push('eleInstallType');
        if (values.eleInstallType === 0) {
            fields.push('eleLampReplacement');
            fields.push('eleRepair');
            fields.push('eleSocketNotWoking');
            fields.push('eleDefectReport');
                fields.push('report');
        }
        if (values.eleInstallType === 1) {
            fields.push('eleInstallNewKitchen');
            fields.push('eleReplaceIndividualComponent');
            fields.push('eleRepairIndividualComponent');
        }
        if (values.eleInstallType === 2) {
            fields.push('eleInstallNewBath');
            fields.push('eleReplaceIndividualComponent');
            fields.push('eleRepairIndividualComponent');
        }
        if (values.eleInstallType === 3) {
            fields.push('eleReinstallation');
            fields.push('eleReplaceIndividualComponent');
            fields.push('eleRepairIndividualComponent');
        }
        if (values.eleInstallType === 4) {
            fields.push('report');

        }
        fields.push('message');
    }

    let result = {};
    for (let i = 0; i < fields.length; i++) {
        const fieldName = fields[i];
        const ctField = ContactConstant[fieldName];
        
        const value = values[fieldName];
        if (ctField.data == undefined)
            result[ctField.label] = value;


        else if (fieldName == "time") {
        }
         else  {
            result[ctField.label] = ctField.data.find(t => t.value == value).label;

        }
    }

    return result;
};